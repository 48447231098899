.case-study {
    .photo-hero__content .headline.large2 {
        margin-top: 3.75em;
    }

    .photo-hero__content .display.large {
        margin-top: 1.875em;
    }

    @media(max-width: 768px) {
        .photo-hero__content .display.large,
        .photo-hero__content .headline.large2 {
            padding-top: 65px;
        }
    }

    .photo-hero__content.legacy {
        aspect-ratio: 16 / 7;
    }

    .case-study-opener .statement + .copy {
        @apply mt-8;
    }

    :where(.case-study-graphic) {
        @apply h-[510px] md:h-[810px];
    }

    .case-study-graphic.short {
        @apply h-[320px] md:h-[500px];
    }

    .case-study-graphic.pulled-down-content {
        @apply h-[410px] md:h-[670px];
        margin-bottom: rem(74px);
    }

    .case-study-graphic__artboard > .centered {
        @apply m-auto;
    }

    .case-study-graphic__artboard > .pulled-down {
        @apply mx-auto mt-auto;
        margin-bottom: rem(-64px);
    }

    .case-study-graphic__artboard {
        background-size: cover;
        background-position: center;
    }

    .case-study-graphic__artboard > img {
        @apply w-full h-full object-cover;
    }

    .case-study-caption {
        @apply mt-2.5 max-w-6-cols;
    }

    .list-columns + .list-columns {
        margin-top: rem(102px);
    }

    .body-section {
        @apply flex justify-center w-full;
    }

    .body-section.std-y-padding {
        @apply py-12 md:py-24;
    }

    .body-section .columns {
        @apply flex flex-col gap-4 w-full;
    }

    .body-section .columns > * {
        @apply grow;
    }

    .body-section .col-with-list {
        @apply md:flex-row lg:gap-44;
    }

    .body-section .col-with-image {
        @apply lg:flex-row gap-4;
    }

    .body-section .text-block {
        @apply flex flex-col max-w-[830px];
    }

    .body-section > .image-block {
        @apply flex flex-col items-center gap-6;
    }

    .section-heading {
        @apply uppercase;
    }

    .standard-column {
        @apply flex flex-col gap-4 items-start;
    }

    .multi-image {
        min-width: 160px;
        max-width: 256px;
        max-height: 333px;
        flex: 1 1 160px;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    ol:not([class]),
    ul:not([class]) {
        padding-left: 45px;
    }

    ol li {
        list-style-type: decimal;
    }

    ul li {
        list-style-type: disc;
    }

    .col-with-list ul {
        padding-left: 2px;
        list-style-position: inside;
    }

    .col-with-list ul li {
        list-style-type: circle;
    }

    .has-header {
        @apply mt-10;
    }

    .case-study-cta {
        height: rem(300px);

        @screen lg {
            height: rem(500px);
        }
    }

    .copy-block + .inline-graphic {
        margin-top: rem(112px);
    }

    .section > *:first-child {
        margin-top: 0;
    }

    :where(.case-study-graphic-wrap, .testimonial, .case-study-opener)  + :where(.case-study-graphic-wrap, .testimonial) {
        margin-top: rem(112px);
    }

    .inline-graphic + .inline-graphic {
        margin-top:  rem(72px);
    }

    :where(.case-study-opener, .copy-block) + :where(.case-study-opener, .copy-block) {
        margin-top: rem(72px);
    }

    .pseudo-quotes:before {
        content: "“"
    }

    .pseudo-quotes:after {
        content: "”"
    }

    .industry-service-block {
        @apply flex flex-col gap-9;
    }

    .hero-summary ul {
        @apply flex flex-col md:flex-row gap-14 justify-between;
    }

    @media(max-width: 767px) {
        .main-summary {
            @apply sr-only;
        }
    }

    .takeaway {
        @apply flex flex-col w-[345px] items-center justify-start gap-2.5;
    }

    .icon-container {
        @apply relative h-24 w-24 flex items-center justify-center;
    }

    .darkened-bg {
        filter: brightness(0.7);
        z-index: 1;
        @apply absolute inset-0;
    }

    .icon-container > img {
        max-height: 63px;
        max-width: 63px;
        height: auto;
        width: auto;
        z-index: 2;
    }

    @screen md {
        .pseudo-quotes:before {
            content: ""
        }

        .pseudo-quotes:after {
            content: ""
        }

        .industry-service-block {
            @apply flex-row gap-6;
        }

        .takeaway {
            @apply items-start;
        }
    }

    .centered--y {
        @apply absolute w-full;
        top: 50%;
        transform: translateY(-50%);
    }

    .artboard-content img {
        @apply mx-auto object-contain h-full;
    }

    .artboard-content {
        position:  relative;
        z-index:  20;
        max-height:  100%;
    }

    a {
        text-decoration:  underline;
    }

    a.no-underline {
        text-decoration:  none;
    }

    p {
        @apply mb-4;
    }

    p.mb-0 {
        margin-bottom:  0;
    }

    .overzoom img {
        margin:  0 auto;
        width: 106%;
        max-width:  initial;
        margin-left:  -3%;
    }

    .overzoom-caption {
        @apply container;
    }

    @screen xl {
        .overzoom img {
            width:  100%;
            max-width:  100%;
            margin-left:  initial;
        }

        .overzoom-caption {
            max-width: unset;
            padding: unset;
        }
    }
}

.case_studies__list {
    @apply space-y-16 pb-16;
}

@screen lg {
    .case_studies__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 32px;
    }

    .small_case_study {
        flex: 1 0 40%;
    }
}