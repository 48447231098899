
.wysiwyg.block--text {
  h4 {
    @apply font-flexa;
  }
}

/* TODO: Make sure this doesn't get picked up by the blog styles in a cleaner way -- right now I'm undoing if we realize
    we're in a blog post*/
.blog-post  .wysiwyg.block--text {
  h4 {
    font-family: unset;
  }
}

.wysiwyg {
	h4 {
		@apply mb-8;
	}

	p, ul {
		@apply txt-lg mb-6;
	}

	strong {
		font-weight: 600;
	}

	ul {
    padding-left: 1em;
		list-style-type:  disc;
	}

  a {
    @apply underline;
  }

  a:hover {
    @apply opacity-75;
  }

  *:last-child {
    @apply mb-0;
   }
}
