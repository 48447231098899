@import 'pygments.css';
@import 'prism-syntax-highlighter.css';

.blog-post {
	article > *:first-child {
		margin-top:  0 !important;
	}

	article > * {
		@apply max-w-8-cols;
	}

	article figure.image--full-width {
		max-width: 100%;
	}
	article figure.image--8-cols {
		@apply max-w-8-cols;
	}
	article figure.image--4-cols {
		@apply max-w-4-cols;
	}

	.subhead {
		@apply txt-2xl font-semibold mb-16;
	}
	div  {}

	pre, p, ul, ol {
		margin-bottom: 1rem;
	}

	embed, iframe, blockquote, figure, .interview {
		margin-top: 2rem;
		margin-bottom: 2rem;
  }

	iframe {
		max-width:  100%;
  }
  
  img.round-headshot {
    @apply my-6 border border-gray-300;
  }
  
	figure {
		img {
			@apply my-0
		}
	}

	figcaption {
		@apply txt-xs;
		margin-top: rem(10px);
	}

	ol, ul {
		    padding-left: 3rem;
	}

	ul {list-style: disc;}
	ol {list-style: decimal;}
	li {
	}
	a  {
		@apply underline;
		text-decoration-color: theme(colors.spectrum.blue);
	}
	a:hover {
		@apply text-blue-255;
	}
	p {
		@apply mb-4;
		line-height:  1.575;
	}
	strong {}
	hr  {}
	h1 {
		@apply txt-2xl font-semibold mt-10 mb-8;
  }
  
  h1 + h2 {
    margin-top: 0;
  }

  /* Our goal is to navigate away from styling h tags and to apply styles using classes only.
  This section, however, ensures backward compatibility so that the h2 and h3 tags 
  that have been widely used in insights without additional classes are styled in accordance with 
  the new typography rules set in typography-redo.css. */
	h2 {
    font-family: "GT Flexa";
    font-size: 1.3125rem;
    line-height: 140%;
    font-weight: 300;
  }
	h3 {
    font-family: "GT Flexa";
    font-size: 1.1875rem;
    line-height: 140%;
    font-weight: 300;
  }

  @media screen(md) {
    h2 {
       font-size: 1.5rem;
    }
    h3 {
      font-size: 1.3125rem;
    } 
  }

  @media screen(lg) {
    h2 {
       font-size: 1.75rem;
    }
    h3 {
      font-size: 1.5rem;
    } 
  }

  /* end of backward compatibility section */

	h4 {
		/*font-size: 18px;*/
	}
	h5 {}
	h6 {}
	code {
		@apply font-mono;
	}
	p > code {
		@apply bg-gray-200 px-1;
		border-radius: 2px;
	}
	em {
		@apply italic;
	}
	blockquote {
		@apply txt-2xl italic px-16 my-16;
	}

	blockquote cite {
			@apply txt-base font-semibold mt-4 block;
			font-style: normal;
	}


	ol {}
	pre {}
	div {}
	table {}
	thead {}
	tr {}
	th {}
	tbody {}
	td {}
	colgroup {}
	col  {}
	td  {}
	table  {}
	b {
		@apply font-semibold;
	}

	.interview__question {
		@apply italic font-bold mb-6;
	}

	.interview {
		@apply space-y-12;
	}
}


/* Styles for modals */
dialog::backdrop {
  @apply bg-charcoal/75;
}

