:root {
  --nav-height-lg: 116px;
  --nav-height-mobile: 104px;
}

.nav-bar--dark {
    @apply bg-charcoal text-white;
}

.nav-bar--light {
    @apply bg-gray-200;
}

.nav-bar--transparent, .nav-bar--light, .nav-bar--white  {
    @apply text-charcoal
}

.nav-bar--transparent {
    @apply bg-transparent;
}

.nav-bar--transparent--light {
  @apply bg-transparent text-white absolute top-0 w-full z-10;
}

.nav-bar--transparent--dark {
  @apply bg-transparent text-black absolute top-0 w-full z-10;
}


.nav-bar--open {
    color: white !important;
}

#nav-opener--js {
    @apply cursor-pointer;
    width: rem(40px);
}

#nav-spacer--js {
  height: var(--nav-height-mobile);
}

@screen lg {
  #nav-spacer--js {
    height: var(--nav-height-lg);
  }
}

.nav-bar {
    transition: background-color 400ms;
}

.nav-bar--open {
    @apply bg-spectrum-blue;
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    z-index: 1000;
}

.nav-bar--open :focus-visible {
  outline: none;
  border: 4px solid #FCE517;
  border-radius: 4px;
  box-shadow: 0 0 0 6px rgba(252, 229, 23, 0.33);
}

.nav-bar--open .nav-menu {
  @apply block;
}

.nav-bar--open a:focus-visible {
  margin-left: -4px;
}

.nav-bar--open button:focus-visible {
  margin-top: -4px;
}

#nav-opener--js .hamburger-bar {
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-play-state: paused;
}

#nav-opener--js #nav-hamburger--top-bar {
  animation-name: hamburger-to-x--top;
}

#nav-opener--js #nav-hamburger--middle-bar {
  animation-name: hamburger-to-x--middle;
}

#nav-opener--js #nav-hamburger--bottom-bar {
  animation-name: hamburger-to-x--bottom;
}

#nav-opener--js.animating {
  pointer-events: none;
}

#nav-opener--js.animating .hamburger-bar {
  animation-play-state: running;
}

.nav-bar--open #nav-menu--js {
  position: absolute;
  top: 6rem;
  width: 100%;
  left: 0;
}

@media only screen and (min-width: 600px) {
  .nav-bar--open #nav-menu--js {
    top: 8rem;
  }
}

/*TODO: Use variables to reduce duplication in these matrix declarations */

@keyframes hamburger-to-x--top {
  from {
    transform: matrix(1.00,0.00,0.00,1.00,0,0);
  }

  50% {
    transform: matrix(1.00,0.00,0.00,1.00,0,9);
  }

  to {
    transform: matrix(0.75,0.75,-0.75,0.75,6,-6);
  }
}

@keyframes hamburger-to-x--middle {
  from {
    transform: matrix(1.00,0.00,0.00,1.00,0,0);
  }

  50% {
    transform: matrix(1.00,0.00,0.00,1.00,0,0);
  }

  to {
    transform: matrix(0.75,0.75,-0.75,0.75,12.75,-12.75);
  }
}

@keyframes hamburger-to-x--bottom {
  from {
    transform: matrix(1.00,0.00,0.00,1.00,0,0);
  }

  50% {
    transform: matrix(1.00,0.00,0.00,1.00,0,-9);
  }

  to {
    transform: matrix(0.75,-0.75,0.75,0.75,-10.5,10.5);
  }
}

body.showing-nav {
    width: 100%;
    position: fixed;
    overflow: hidden;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    /* Other browsers */
    overscroll-behavior: none;
    scrollbar-gutter: stable;
}
