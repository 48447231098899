.accordion-content-js {
    transition: all 400ms;
    overflow: hidden;
    height: 0;
}

.accordion-opener-js {
    cursor: pointer;
}

.service-accordion-wrap-js.active .service-accordion-js:not(.active){
    opacity: 0.2;
}

.service-accordion-js {
    transition: opacity 400ms;
    opacity: 1;
}