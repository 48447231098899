.truncated-slider {
    @apply overflow-x-hidden relative;
    --lg-side-margin: calc((100vw - 1024px) / 2);
    --lg-side-pad: 64px;
    --xl-side-margin: calc((100vw - 1440px) / 2);
    --xl-side-pad: 90px;
    --left-col: 252px;
}

.interior-slider {
}

.interior-slider__left-col {
    max-width: var(--left-col);
}

.interior-slider__right-col {
}


.interior-slider__img {
    @apply mb-3;
}

.interior-slider__right-col-content {
    @apply space-y-8;
}

@media screen(lg) {
    .interior-slider {
        @apply flex;
    }

    .interior-slider__left-col {
        @apply w-1/5 shrink-0 pr-16;
    }

    .interior-slider__img {
        @apply w-6-cols--plus mb-7;
        height: rem(460px);
    }

    .interior-slider__right-col-content {
        @apply flex space-x-16 space-y-0 overflow-x-auto pb-4 pr-8;
    }

    div.interior-slider__right-col-content::-webkit-scrollbar {
        height: 8px;
    }

    div.interior-slider__right-col-content::-webkit-scrollbar-track {
        background: linear-gradient(180deg, theme(colors.gray.100) 0%, theme(colors.gray.100) 42%, theme(colors.charcoal) 42%, theme(colors.charcoal) 62%, theme(colors.gray.100) 62%);
    }

    div.interior-slider__right-col-content::-webkit-scrollbar-thumb {
        background: theme(colors.charcoal);
    }

    .interior-slider__right-col {
        width: calc(80% + var(--lg-side-pad));
    }
}

@media screen(xl) {
    .interior-slider__right-col {
        width: calc(100vw - var(--left-col) - var(--xl-side-pad) - var(--xl-side-margin));
    }

    .truncated-slider-container {
        padding-right: 0;
        margin-left: var(--xl-side-margin);
        margin-right: initial;
        max-width: none;
    }
}

