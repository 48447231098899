.block-text {
    font-size: .85em;
}

.half-spark-logo svg {
    position: absolute;
    height: 105%;
    top: 50%;
    right: 0%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media screen and (max-width: 1200px) {
    .half-spark-logo svg {
        position: absolute;
        top: 50%;
        max-width: 11em;
        right: 0%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
