.home-carousel-forward-arrow {
    transform: scale(-1, 1);
    margin-left: auto;
}

.home-carousel-back-arrow {
    margin-right: auto;
}

.glide__bullet {
    margin: 0 rem(10px);
    opacity: 0.2;
}

.glide__bullet--active {
    opacity: 1;
}

.glide__ui {
    @apply mx-auto flex justify-between text-white items-center;
    max-width: rem(313px);
}