.js .radio-buttons-js {
  input[type='checkbox'] {
    display: none;
  }

  svg {
    display: inline;
  }

  rect {
    stroke: theme('colors.charcoal');
    shape-rendering: crispEdges;
  }

  rect.active {
    fill: theme('colors.charcoal');
  }

  input[type='checkbox']:checked + .radio-button {
    rect { fill: theme('colors.charcoal') }
  }

  .radio-button {
    @apply mr-1;
    display: flex;
    align-items: center;
  }
}

.filter-dropdown__header {
  line-height: 2;
}

.filter-dropdown {
  @apply flex flex-col box-content relative;

  &__menu {
    @apply border-charcoal border-t-0 w-max bg-gray-200;
    border-width: 1.5px;
    display: none;
    position: absolute;
    &.active {
      @apply block z-50;
      top: 36px;
      color: theme('colors.charcoal');
    }
  }
}

.filter-dropdown-option {
  @apply flex cursor-pointer;
  label {
    @apply grow pl-1;
    cursor: inherit;
  }
}

.contact-form-input {
  @apply block w-full;
}

.contact-form-input::placeholder {
  @apply text-charcoal;
}