.headline {
  font-family: "GT Flexa";
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.headline.large1 {
  font-size: 2rem;
}

.headline.large1.dynamic {
  font-size: clamp(1.88rem, calc(1.35rem + 2.8vw), 2.75rem);
}

.headline.large2 {
  font-size: 2.625rem;
}

.headline.medium1 {
  font-size: 1.5rem;
}

.headline.medium2 {
  font-size: 1.75rem;
}

.headline.small1 {
  font-size: 1.3125rem;
}

.headline.small1.font-medium {
  font-weight: 500;
}

.headline.small2 {
  font-size: 1.1875rem;
}

.headline.mono {
  font-family: "GT Flexa Mono";
}

.display {
  font-family: "GT Flexa";
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  font-size: 3rem;
}

.display.large {
  font-size: 3.5rem;
}

.eyebrow {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: 800;
  font-size: 0.688rem;
  line-height: 130%;
}

.subtitle {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 150%;
}

.subtitle.large {
  font-size: 1.5rem;
  line-height: normal;
}

.body.large {
  font-size: 1rem;
}

.body {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  --small-size: 0.875rem;
  --small-line-height: 150%;
}

.body.small {
  font-size: var(--small-size);
  line-height: var(--small-line-height);
}

@media(max-width: 768px) {
  .body.figcaption {
    font-size: var(--small-size);
    line-height: var(--small-line-height);
  }
}

.btn {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: 800;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
}

.btn.small {
  font-size: 0.875rem;
}

@media screen(md) {
  .headline.large1 {
    font-size: 3.5rem;
  }

  .headline.large1.dynamic {
    font-size: 3.5rem;
  }

  .headline.large2 {
    font-size: 3rem;
  }

  .headline.medium1 {
    font-size: 2.625rem;
  }

  .headline.medium2 {
    font-size: 2rem;
  }

  .headline.small1 {
    font-size: 1.5rem;
  }

  .headline.small2 {
    font-size: 1.3125rem;
  }

  .display {
    font-size: 4.5rem;
  }
  
  .display.large {
    font-size: 5.375rem;
  }

  .eyebrow {
    font-size: 0.75rem;
  }

  .subtitle {
    font-size: 1.3125rem;
  }

  .subtitle.large {
    font-size: 1.75rem;
  }

  .body.large {
    font-size: 1.1875rem;
  }

  .body {
    font-size: 1.0625rem;
  }

  .body.small {
    font-size: 0.9375rem;
  }

  .btn {
    font-size: 0.9375rem;
  }

  .btn.small {
    font-size: 0.875rem;
  }
}

@media screen(lg) {
  .headline.large1 {
    font-size: 4rem;
  }

  .headline.large1.dynamic {
    font-size: 4rem;
  }

  .headline.large2 {
    font-size: 3.5rem;
  }

  .headline.medium1 {
    font-size: 3rem;
  }

  .headline.medium2 {
    font-size: 2.25rem;
  }

  .headline.small1 {
    font-size: 1.75rem;
  }

  .headline.small2 {
    font-size: 1.5rem;
  }
  
  .display {
    font-size: 5rem;
  }
  
  .display.large {
    font-size: 6rem;
  }
  
  .eyebrow {
    font-size: 0.813rem;
  }
  
  .subtitle {
    font-size: 1.5rem;
  }

  .subtitle.large {
    font-size: 2rem;
  }

  .body.large {
    font-size: 1.3125rem;
  }

  .body {
    font-size: 1.125rem;
  }

  .body.small {
    font-size: 1rem;
  }

  .btn {
    font-size: 1rem;
  }

  .btn.small {
    font-size: 0.875rem;
  }
}
