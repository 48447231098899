:root {
	font-size: 16px;
	line-height: 1.375;
	--grid-gap:  1.875rem;
  --pygment-palette-1: theme(colors.charcoal);
	--pygment-palette-2: theme(colors.gray.200);
	--pygment-palette-3: theme(colors.spectrum.red);
	--pygment-palette-4: theme(colors.gray.100);
	--pygment-palette-5: theme(colors.spectrum.yellow);
	--pygment-palette-6: theme(colors.spectrum.yellow);
	--pygment-palette-7: theme(colors.spectrum.yellow);
	--pygment-palette-8: theme(colors.spectrum.green);
	--pygment-palette-9: theme(colors.spectrum.cyan);
	--pygment-palette-10: theme(colors.spectrum.cyan);
	--pygment-palette-11: theme(colors.spectrum.blue);
	--pygment-palette-12: theme(colors.spectrum.indigo);

  /* In order to synchronize two separate code highlighters,
  I'm reusing variables.*/
  --prism-palette-1: var(--pygment-palette-1);
  --prism-palette-2: var(--pygment-palette-2);
  --prism-palette-3: var(--pygment-palette-2);
  --prism-palette-4: var(--pygment-palette-9);
  --prism-palette-5: var(--pygment-palette-9);
  --prism-palette-6: var(--pygment-palette-5);
  --prism-palette-7: var(--pygment-palette-3);
  --prism-palette-8: var(--pygment-palette-3);
  --prism-palette-9: var(--pygment-palette-3);
  --prism-palette-10: rgba(255, 0, 0, .3);
  --prism-palette-11: rgba(0, 255, 128, .3);
}
