.hero {
    @apply pt-16;
}

.hero-pb--small {
    @apply pb-8;
}

.hero-pb--medium {
    @apply pb-24;
}

.hero-pb--large {
    @apply pb-24;
}

@screen lg {

    .hero {
        padding-top: 7.6rem;
    }

    .hero--min-height {
        min-height: rem(486px);
    }

    .hero-pb--small {
        padding-bottom: rem(112px);
    }

    .hero-pb--medium {
        padding-bottom: rem(208px);
    }

    .hero-pb--large {
        padding-bottom: rem(326px);
    }

}
.hero-with-subtitle {
    @apply md:h-[490px];
}

.hero-with-subtitle svg {
    @apply inset-x-0 sm:w-1/2 md:left-auto md:w-1/3 md:right-12 lg:right-16 xl:right-[90px];
}

.hero-with-subtitle svg.svg-desktop {
    @apply absolute top-0;
}

.hero-with-subtitle svg.svg-mobile {
    @apply relative md:absolute md:bottom-0;
}

.hero-with-subtitle h2,
.hero-with-subtitle p {
    @apply relative z-10;
}