
.highlight {
	@apply  my-8 p-4;
	pre { line-height: 125%; }
	td.linenos .normal { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
	span.linenos { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
	td.linenos .special { color: var(--pygment-palette-1); background-color: var(--pygment-palette-6); padding-left: 5px; padding-right: 5px; }
	span.linenos.special { color: var(--pygment-palette-1); background-color: var(--pygment-palette-6); padding-left: 5px; padding-right: 5px; }
	    overflow: auto;
}
.highlight .hll { background-color: var(--pygment-palette-11) }
.highlight { background: var(--pygment-palette-1); color: var(--pygment-palette-2) }
.highlight .c { color: var(--pygment-palette-8) } /* Comment */
.highlight .err { color: var(--pygment-palette-2) } /* Error */
.highlight .esc { color: var(--pygment-palette-2) } /* Escape */
.highlight .g { color: var(--pygment-palette-2) } /* Generic */
.highlight .k { color: var(--pygment-palette-3) } /* Keyword */
.highlight .l { color: var(--pygment-palette-2) } /* Literal */
.highlight .n { color: var(--pygment-palette-2) } /* Name */
.highlight .o { color: var(--pygment-palette-2) } /* Operator */
.highlight .x { color: var(--pygment-palette-2) } /* Other */
.highlight .p { color: var(--pygment-palette-2) } /* Punctuation */
.highlight .ch { color: var(--pygment-palette-8) } /* Comment.Hashbang */
.highlight .cm { color: var(--pygment-palette-8) } /* Comment.Multiline */
.highlight .cp { color: var(--pygment-palette-4) } /* Comment.Preproc */
.highlight .cpf { color: var(--pygment-palette-8) } /* Comment.PreprocFile */
.highlight .c1 { color: var(--pygment-palette-8) } /* Comment.Single */
.highlight .cs { color: var(--pygment-palette-8) } /* Comment.Special */
.highlight .gd { color: var(--pygment-palette-2) } /* Generic.Deleted */
.highlight .ge { color: var(--pygment-palette-2) } /* Generic.Emph */
.highlight .gr { color: var(--pygment-palette-2) } /* Generic.Error */
.highlight .gh { color: var(--pygment-palette-2) } /* Generic.Heading */
.highlight .gi { color: var(--pygment-palette-2) } /* Generic.Inserted */
.highlight .go { color: var(--pygment-palette-2) } /* Generic.Output */
.highlight .gp { color: var(--pygment-palette-2) } /* Generic.Prompt */
.highlight .gs { color: var(--pygment-palette-2) } /* Generic.Strong */
.highlight .gu { color: var(--pygment-palette-2) } /* Generic.Subheading */
.highlight .gt { color: var(--pygment-palette-2) } /* Generic.Traceback */
.highlight .kc { color: var(--pygment-palette-3) } /* Keyword.Constant */
.highlight .kd { color: var(--pygment-palette-3) } /* Keyword.Declaration */
.highlight .kn { color: var(--pygment-palette-3) } /* Keyword.Namespace */
.highlight .kp { color: var(--pygment-palette-3) } /* Keyword.Pseudo */
.highlight .kr { color: var(--pygment-palette-3) } /* Keyword.Reserved */
.highlight .kt { color: var(--pygment-palette-12) } /* Keyword.Type */
.highlight .ld { color: var(--pygment-palette-2) } /* Literal.Date */
.highlight .m { color: var(--pygment-palette-2) } /* Literal.Number */
.highlight .s { color: var(--pygment-palette-10) } /* Literal.String */
.highlight .na { color: var(--pygment-palette-2) } /* Name.Attribute */
.highlight .nb { color: var(--pygment-palette-2) } /* Name.Builtin */
.highlight .nc { color: var(--pygment-palette-2) } /* Name.Class */
.highlight .no { color: var(--pygment-palette-9) } /* Name.Constant */
.highlight .nd { color: var(--pygment-palette-2) } /* Name.Decorator */
.highlight .ni { color: var(--pygment-palette-2) } /* Name.Entity */
.highlight .ne { color: var(--pygment-palette-2) } /* Name.Exception */
.highlight .nf { color: var(--pygment-palette-7) } /* Name.Function */
.highlight .nl { color: var(--pygment-palette-2) } /* Name.Label */
.highlight .nn { color: var(--pygment-palette-2) } /* Name.Namespace */
.highlight .nx { color: var(--pygment-palette-2) } /* Name.Other */
.highlight .py { color: var(--pygment-palette-2) } /* Name.Property */
.highlight .nt { color: var(--pygment-palette-2) } /* Name.Tag */
.highlight .nv { color: var(--pygment-palette-5) } /* Name.Variable */
.highlight .ow { color: var(--pygment-palette-2) } /* Operator.Word */
.highlight .pm { color: var(--pygment-palette-2) } /* Punctuation.Marker */
.highlight .w { color: var(--pygment-palette-2) } /* Text.Whitespace */
.highlight .mb { color: var(--pygment-palette-2) } /* Literal.Number.Bin */
.highlight .mf { color: var(--pygment-palette-2) } /* Literal.Number.Float */
.highlight .mh { color: var(--pygment-palette-2) } /* Literal.Number.Hex */
.highlight .mi { color: var(--pygment-palette-2) } /* Literal.Number.Integer */
.highlight .mo { color: var(--pygment-palette-2) } /* Literal.Number.Oct */
.highlight .sa { color: var(--pygment-palette-10) } /* Literal.String.Affix */
.highlight .sb { color: var(--pygment-palette-10) } /* Literal.String.Backtick */
.highlight .sc { color: var(--pygment-palette-10) } /* Literal.String.Char */
.highlight .dl { color: var(--pygment-palette-10) } /* Literal.String.Delimiter */
.highlight .sd { color: var(--pygment-palette-10) } /* Literal.String.Doc */
.highlight .s2 { color: var(--pygment-palette-10) } /* Literal.String.Double */
.highlight .se { color: var(--pygment-palette-10) } /* Literal.String.Escape */
.highlight .sh { color: var(--pygment-palette-10) } /* Literal.String.Heredoc */
.highlight .si { color: var(--pygment-palette-10) } /* Literal.String.Interpol */
.highlight .sx { color: var(--pygment-palette-10) } /* Literal.String.Other */
.highlight .sr { color: var(--pygment-palette-10) } /* Literal.String.Regex */
.highlight .s1 { color: var(--pygment-palette-10) } /* Literal.String.Single */
.highlight .ss { color: var(--pygment-palette-10) } /* Literal.String.Symbol */
.highlight .bp { color: var(--pygment-palette-2) } /* Name.Builtin.Pseudo */
.highlight .fm { color: var(--pygment-palette-7) } /* Name.Function.Magic */
.highlight .vc { color: var(--pygment-palette-5) } /* Name.Variable.Class */
.highlight .vg { color: var(--pygment-palette-5) } /* Name.Variable.Global */
.highlight .vi { color: var(--pygment-palette-5) } /* Name.Variable.Instance */
.highlight .vm { color: var(--pygment-palette-5) } /* Name.Variable.Magic */
.highlight .il { color: var(--pygment-palette-2) } /* Literal.Number.Integer.Long */
