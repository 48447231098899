@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        border-color: theme(colors.charcoal);
    }

    html {
        font-family: avenir, system-ui, sans-serif;
        color: theme(colors.charcoal);
    }
}

@screen lg {
    .container {
        max-width: 1440px;
    }
}

:root {
    --button-height: rem(28px);
    --rollover-speed: 200ms;
}

strong {
  font-weight: 600;
}

.accent-underline {
    text-decoration-color: theme(colors.spectrum.blue);
    transition: color 400ms;
}

.accent-underline:hover {
    color: theme(colors.spectrum.blue);
}


.button {
    @apply h6 cursor-pointer uppercase flex;
    width: rem(200px);
    height: var(--button-height);
    border-radius: 3px;
    border-width: 1px;
    transition: background-color var(--rollover-speed), color var(--rollover-speed);
}

.button > * {
    @apply text-center w-full inline-block;
    line-height: var(--button-height);
}

.button__content a {
  @apply inline-block w-full;
  text-decoration: none !important;
}

.button--narrow {
    width: rem(150px);
}

.button--freeform {
    @apply px-4;
    width: max-content;
}

/* TODO: Real fragile -- initially we were using direct application of colors to
    every button, but with the push for components we want them to pick up
    their cues from their context. Problem is, a grandparent and a parent have
    equal weight to a css selector -- it pays no attention to which background
    color is nearest to the button. To increase specificity, I added one extra
    layer, so that a white background which is contained within a dark
    background will still apply the correct button color.*/

.bg-charcoal .bg-white .button, .bg-white .button, .bg-gray-200 .button, .bg-gray-300 .button, .bg-gray-400 .button, .button--black {
    background-color: theme('colors.charcoal');
    color: white;
    border-color: theme('colors.charcoal');

  &:hover {
    background-color: white;
    color: theme('colors.charcoal');
   }
}

.bg-charcoal .button, .button--white {
    background-color: white;
    color: theme('colors.charcoal');
    border-color: white;

    &:hover {
      background-color: theme('colors.charcoal');
      color: white;
   }
}

/* TODO: Again, real fragile. Hammering the white button style for these */
.cta-ribbon .button, .image-backdrop .button, .scrim-banner .button {
    background-color: white !important;
    color: theme('colors.charcoal') !important;
    border-color: white !important;

    &:hover {
      background-color: theme('colors.charcoal') !important;
      color: white !important;
   }
}

.rollover-svg {
  .positive {
    transition: fill var(--rollover-speed);
  }
  .negative {
    transition: fill var(--rollover-speed);
  }
}

.rollover-svg {
  .positive {
    fill: theme('colors.gray.100');
  }
  .negative {
    fill: theme('colors.charcoal');
  }
}

.rollover-svg:hover {
  .positive {
    fill: theme('colors.charcoal');
  }
  .negative {
    fill: theme('colors.gray.100');
  }
}

.rollover-svg.reversed {
  .negative {
    fill: theme('colors.gray.100');
  }
  .positive {
    fill: theme('colors.charcoal');
  }
}

.rollover-svg.reversed:hover {
  .negative {
    fill: theme('colors.charcoal');
  }
  .positive {
    fill: theme('colors.gray.100');
  }
}



/*.reversed .button {*/
/*    background-color: white !important;*/
/*    color: theme('colors.charcoal') !important;*/
/*    border-color: white;*/
/*}*/

/*a:hover {
	text-decoration:  underline;
	text-decoration-color:  inherit;
}

a:hover svg {
	text-decoration:  none;
}*/

.border-bottom {
    border-bottom-width: 3px;
}

.border-top {
    border-top-width: 3px;
}

@media screen(md) {
    .border-bottom {
        border-bottom-width: 5px;
    }

    .border-top {
        border-top-width: 5px;
    }
}



@media screen and (min-width: 600px) {
    .clients-columns {
        @apply columns-2;
    }
}

@media screen(lg) {
    .clients-columns {
        @apply columns-3;
    }
}

@media screen(xl) {
    .clients-columns {
        @apply columns-4;
    }
}

.select-client {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}

.inline svg {
    display: inline;
}

.contact-button {
    @apply flex border;
    height: 50px;
    width: 150px;
}

.contact-block-wrap {
    @apply flex flex-wrap -mt-[87px];
}

.contact-block {
    @apply w-1/3 mt-[87px];
}

.contact-form-input {
    @apply pb-1 border-b;
}


/* The idea here is to put a blank line of text at the top of the div so that
   the real content's top edge will align with the baseline of the txt-2xl
   heading in the lefthand column. */

.pt-txt-2xl:before {
    @apply txt-2xl;
    content: '\0000a0';
}


@media screen(lg) {
    .leadership-team-grid {
        @apply grid grid-cols-3 gap-[30px] items-baseline;
    }
}

.image-backdrop {
    height: rem(500px);
}

.cyan-border {
    border: 3px solid cyan;
}

.squeezed-apostrophe {
    margin-left: -0.1em;
    margin-right: -0.22em;
}

.round-headshot {
    @apply rounded-full;
    width: rem(175px);
    height: rem(175px);
}

.round-headshot--lg {
    width: rem(288px);
    height: rem(288px);
}

.wheel-anim {
    animation-duration: 10s;
    animation-name: spin-wheel;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.wheel-anim:hover {
    animation-duration: 0.1s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
}

@keyframes spin-wheel {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}


.portfolio-color-card {
    @apply p-6 relative;
}

.portfolio-color-card__scrim {
    @apply bg-charcoal;
    transition: opacity 400ms;
    opacity: 0;
}

.portfolio-color-card:hover .portfolio-color-card__scrim {
    opacity: 0.15;
}


@media screen(lg) {
    .portfolio-color-card {
        @apply p-10
    }
}


.leadership-gallery__tile {
    @apply mb-14 block px-9 box-content;
    width: rem(175px);
}

.leadership-card {
    @apply mb-14 pt-8;
}

@media screen(lg) {
    .leadership-card {
        @apply mb-0;
        min-height: rem(630px);
    }
}

.eighth-light-logo {
    @apply inline-block;
    width: 50px;
}

@media screen(lg) {
    .eighth-light-logo {
        width: 76px;
    }
}

.img-400x245 {
    width: rem(400px);
    height: rem(245px);
}

.spark-gallery-item-faces {
    position: relative;
}

.spark-gallery-item-front, .spark-gallery-item-back {
    -webkit-transition: -webkit-opacity 200ms;
    transition: -webkit-opacity 200ms;
    -o-transition: -o-opacity 200ms;
    transition: opacity 200ms;
}

.spark-gallery-item-spark, .spark-gallery-item-back > img {
    @apply mx-auto;
    max-width: 12rem;
}

.spark-gallery-item-front--active {
    display: none;
}

@media screen(md) {
    .spark-gallery-item-back {
        opacity: 0;
        z-index: 5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }

    .spark-gallery-item-front {
        display: block;
    }

    .spark-gallery-item-front {
        opacity: 1;
    }

    .spark-gallery-item-faces:hover .spark-gallery-item-front--active {
        opacity: 0;
    }

    .spark-gallery-item-faces:hover .spark-gallery-item-back {
        opacity: 1;
    }
}


.bg-charcoal--fade-out {
    @apply pb-8;
    background: linear-gradient(180deg, theme(colors.charcoal) 90%, transparent)
}

.hover-zoom-wrap {
    overflow: hidden;
}

.hover-zoom-container {
    overflow: hidden;
}

.hover-zoom {
    transition: transform 400ms;
    transform: scale(1);
}

.hover-zoom-area:hover .hover-zoom {
    transform: scale(1.05);
}

.hover-zoom-wrap:hover .hover-zoom {
    transform: scale(1.05);
}

.hover-slide-right--target {
    transition: transform 400ms;
}

.hover-slide-right:hover .hover-slide-right--target {
    transform: translate(1rem);
}

.big-link:hover, .footer a:hover {
    @apply underline;
}

.bg-fountain-blue {
    background-color: #d7e8ee;
    background-color: #c9dfe7;
}

.sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.event-card {
    @apply mb-14 pt-8;
}

.services-headline {
    font-family: "GT Flexa Mono";
    font-size: clamp(1.875rem, -0.678125rem + 10.75vw, 9rem) !important;
    letter-spacing: 0.3rem;
    line-height: 106% !important;
    font-weight: 400;
    vertical-align: middle;
    margin-bottom: 0 !important;
}

.services-headline a {
    -webkit-text-decoration-line: none !important;
    text-decoration-line: none !important;
}

.services-headline a:hover {
    -webkit-text-decoration-line: underline !important;
    text-decoration-line: underline !important;
    opacity: 1 !important;
}

.services-headline a .font-sparks {
    letter-spacing: 0rem;
    font-size: 110%;
}

@media screen(md) {
    .small-copyright-links li {
        display: inline-block;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    
    .small-copyright-links li:first-child {
        padding-left: 0;
    }
}

.first-item {
    order: -1;
}

a.inset-link::after {
    content: '';
    position: absolute;
    inset: 0;
}

.related-content {
    @apply flex flex-col lg:flex-row flex-wrap items-stretch gap-6;
}

.related-content-card {
    @apply flex flex-col self-stretch grow shrink-0 basis-0 gap-4 w-full;
    min-width: 275px;
    max-width: 404px;
}

.spark-bullet {
    background-size: 100%;
    background-clip: text;
    font-size: 1.5rem;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.page-blocks-deck {
    @apply flex flex-row items-start content-start gap-4 w-full flex-wrap;
}

.page-blocks-deck > .small-card-with-icon {
    .card {
        transition: box-shadow 400ms;
        @apply relative bg-white pt-4 pb-6 px-6 flex flex-col items-center md:items-start gap-4 h-[369px] w-full sm:w-[303px];
    }

    .card:hover {
        transition: box-shadow 400ms;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
    }
    
    .card:hover .card-icon-container {
        transition: border-color 400ms;
        border-color: theme(colors.charcoal);
    }
    
    .card-icon-container {
        @apply mb-2 h-24 w-24 p-2 rounded-full border-[6px];
    }
    
    .card-content {
        @apply flex-grow flex flex-col items-center md:items-start gap-4;
    }
}

.page-blocks-deck > .large-card-with-list-back {
    transition: border-color 400ms;
    @apply border-transparent border-8 rounded -m-2 w-full md:w-[614px] min-h-[382px] md:h-[433px] pointer-events-none;
}

.page-blocks-deck > .large-card-with-list-back {
    .card {
        transition: background-color 400ms, color 400ms;
        @apply flex flex-col justify-start items-start relative bg-white w-full h-full md:max-h-full px-6 md:px-12 py-10 md:py-12 pointer-events-auto;
    }
    
    .card-content {
        @apply flex flex-col flex-grow items-center justify-start md:items-start gap-8 md:max-h-full h-full w-full;
    }

    .card:hover .card-content {
        @apply gap-6;
    }

    .card:hover .card-front, .card:hover .plus-icon {
        @apply hidden;
    }

    .card-back, .minus-icon {
        @apply hidden;
    }

    .card:hover .card-back {
        @apply flex;
    }

    .card-back {
        @apply flex-col flex-grow md:flex-wrap pt-2 gap-3 md:max-h-full h-full w-full md:overflow-hidden;
    }

    .card-back > li {
        @apply leading-tight min-w-[10rem] max-w-[15rem];
    }

    .card:hover .minus-icon {
        @apply flex;
    }

    .card-title-container {
        @apply flex flex-col justify-start;
    }

    .card-front {
        @apply flex-grow flex flex-col justify-start;
    }

    .card-back a:hover {
        @apply underline;
    }

    .icon-container {
        @apply relative flex flex-col justify-end min-h-[26px] max-h-[26px] w-[26px];
    }

    .minus-icon {
        @apply absolute left-0 top-3 h-[26px];
    }
}
