/*TYPOGRAPHY*/

/*RULES OF THE ROAD:
>24px? Flexa or Flexa Mono
24px? Flexa or Avenir
<24px? Avenir*/

/* Fine print, copyright info at the bottom of the footer*/
.case-study-caption,
.txt-xs {
  line-height: resolve(16/12);
}
.h6 {
  /* eyebrows */
  @apply tracking-widest font-semibold;
  line-height: resolve(16/12);
}

.txt-base {
  line-height: resolve(22/16);
}
.txt-lg {
  line-height: resolve(24/18);
}
/* So far this is only in 700 weight.*/
.txt-xl {
  line-height: resolve(28/20);
}
.txt-2xl,
.case-study .large-copy {
  line-height: resolve(32/24);
}
.h5 {
  line-height: resolve(40/32);
}
.wysiwyg h4,
.h4 {
  line-height: resolve(44/36);
}
.h3 {
  line-height: resolve(56/48);
}

/* In large footer menu, in mono. In page, sans*/
.h2 {
  line-height: resolve(68/56);
}

/* Hero block at top of page will be in monospaced face, but can also appear in proportional form.*/
.h1 {
  line-height: resolve(70/64);
}

/*There's a piece of text art that I thought might be better as live text -- but it's just a static image now*/
/*    .splash-text {
    font-size: rem(148px);
    line-height: resolve(160/148);
    letter-spacing: 0.15em;
}*/

/* Trying to preserve the scale, I fit a quadratic equation to it (0.996 R2,
   so it was pretty exact) and then messed around with the components to get
   it working a little better for mobile. Those terms are:

c = 14.6333
b = -0.134848
a = 0.267879
    */

.case-study-caption,
.txt-xs,
.h6 {
  font-size: rem(14px);
}
.txt-base {
  font-size: rem(16px);
}
.txt-lg {
  font-size: resolve(14.6333 + (-0.134848 * 3) + (0.267879 * pow(3, 2)))px;
}
.txt-xl {
  font-size: resolve(14.6333 + (-0.134848 * 4) + (0.267879 * pow(4, 2)))px;
}
.txt-2xl,
.case-study .large-copy {
  font-size: resolve(14.6333 + (-0.134848 * 5) + (0.267879 * pow(5, 2)))px;
}
.h5,
.wysiwyg h5 {
  font-size: resolve(14.6333 + (-0.134848 * 6) + (0.267879 * pow(6, 2)))px;
}
.h4,
.wysiwyg h4 {
  font-size: resolve(14.6333 + (-0.134848 * 7) + (0.267879 * pow(7, 2)))px;
}
.h3,
.wysiwyg h3 {
  font-size: resolve(14.6333 + (-0.134848 * 8) + (0.267879 * pow(8, 2)))px;
}
.h2,
.wysiwyg h2 {
  font-size: resolve(14.6333 + (-0.134848 * 9) + (0.267879 * pow(9, 2)))px;
}
.h1,
.wysiwyg h1 {
  font-size: resolve(14.6333 + (-0.134848 * 10) + (0.267879 * pow(10, 2)))px;
  line-height: 1.2;
}

@media screen(md) {
  .case-study-caption,
  .txt-xs,
  .h6 {
    font-size: rem(12px);
  }
  .txt-base {
    font-size: rem(16px);
  }
  .txt-lg {
    font-size: rem(18px);
  }
  .txt-xl {
    font-size: rem(20px);
  }
  .txt-2xl,
  .case-study .large-copy {
    font-size: rem(24px);
  }
  .h5,
  .wysiwyg h5 {
    font-size: rem(32px);
  }
  .h4,
  .wysiwyg h4 {
    font-size: rem(36px);
  }
  .h3,
  .wysiwyg h3 {
    font-size: rem(48px);
  }
  .h2,
  .wysiwyg h2 {
    font-size: rem(56px);
  }
  .h1,
  .wysiwyg h1 {
    font-size: rem(64px);
  }
}
