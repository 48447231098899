

/*@media screen and (min-width: 450px) and (max-width: 639px) {
  .cert-logo-block {
    @apply gap-x-0 gap-y-16;
  }
}

@media screen(xl) {
	.cert-logo-block {
	  @apply flex justify-evenly items-center;
	}
}*/

.grid-gap {
	grid-column-gap:  var(--grid-gap);
	grid-row-gap: var(--grid-gap);
}

.default-gap-x {
	grid-column-gap:  var(--grid-gap);
}

.default-gap-y {
	grid-row-gap:  var(--grid-gap);
}

.insights-grid {
	@apply space-y-16 md:space-y-0 md:grid gap-y-24 grid-cols-12;
	grid-column-gap:  var(--grid-gap);
}

.home-client-grid {
	@apply auto-rows-auto place-items-center grid;
	row-gap: 4rem;
	column-gap: 10rem;
}

.image-grid {
	display: grid;
	grid-row-gap: 8rem;
	grid-column-gap: 6rem;
	justify-content: center;
  }

  .image-grid > * {
	height: 100%;
	max-height: rem(90px);
	max-width: rem(190px);
  }

@media screen(sm) {
	.image-grid {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

@media screen(lg) {
	.image-grid {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

@media screen(sm) {
	.home-client-grid {
		place-items:  center;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: auto;
		row-gap: 6rem;
	}
}

@media screen(md) {
	.home-client-grid {
		grid-template-columns: repeat(4, 1fr);
		column-gap: 8rem;
	}

	.home-client-grid__logo-wrap {
		@apply h-12;
	}
}

.portfolio-grid-image__row-span--1, .portfolio-grid-image__row-span--2 {
	height:  resolve(9/16 * 100)vw;
}

.knife-grid-block {
	/*Line height of base 16px text is 22, so we want a blank line between each*/
	margin-bottom:  rem(22px);
}

@media screen(lg) {
	.knife-grid {
		@apply grid grid-cols-12 items-baseline gap-x-[30px] gap-y-[76px];
	}

	.knife-sub-grid {
		@apply row-span-3 col-span-9 grid grid-cols-9 items-baseline gap-x-[30px] gap-y-[76px];
	}

	.knife-grid--4-line {
		/*We want to allow 4 lines of text in the row, and body copy is 18/22. So 22 * 4 == 88 */
		grid-auto-rows: rem(88px);
	}

	.knife-grid-block {
		@apply col-span-3 mb-0;
	}

	.portfolio-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap:  var(--grid-gap);
		grid-row-gap: var(--grid-gap);
		grid-auto-rows: 290px;
	}

	.portfolio-grid-image__row-span--1 {
		height: rem(245px);
	}
	.portfolio-grid-image__row-span--2 {
		height: rem(565px);
	}
}

#spark-gallery {
	@apply mx-auto;
	display: grid;
	max-width: 18rem;
	grid-row-gap: 5rem;
}

.spark-gallery-item-spark {
	width: 100%;
}

@media screen(md) {
    #spark-gallery {
        max-width: 63.25rem;
		grid-row-gap: initial;
        grid-column-gap: 11vw;
        grid-template-columns: repeat(2, 1fr);
    }

    #spark-gallery > div {
		max-width: rem(178px);
        min-height: rem(440px);
    }

    .spark-gallery-item__quote {
	    margin-right: -4rem;
    }
}

@media screen(lg) {
    #spark-gallery {
        grid-template-columns: repeat(3, 1fr);
    }
}


@media screen(xl) {
	#spark-gallery {
	  	grid-column-gap: rem(242px);
	}

	#spark-gallery > div {
		min-height: rem(440px);
	}
}

.spark-gallery-item__quote {
	@apply mt-10 italic txt-xl;
}

.spark-gallery-item__quote::before {
	content: "“";
	position: relative;
}

.spark-gallery-item__quote::after {
	content: "”";
	position: relative;
}




/* This feels goofy, so I need to explain how it works: I was told to have a
   fluid grid, meaning that the gutters scale with the columns. That means
   that we can get rid of the distinction between gutters and columns, and
   say that intead of a 12 column grid with 11 gutters of 30 px, we have a 42
   gutter grid. (42 * 30 = 1260, the width of the grid on desktop). Once
   that's done, the formula to calculate the fluid width of each column is
   this: 

	Total gutter width = 11 * 30 == 330
   Column width = (1260 - (330)) / 12 == 77.5
   gutter:column ratio = 77.5 / 30 == 2.58333333
	
   (NUM_OF_COLUMNS * 2.58333 + (NUM_OF_COLUMNS - 1)) / 42

	To break that down a little further -- you represent the gutter's
	contribution by taking your number of columns and simply subtracting
	one. That's because in calculating the ratio of gutters to columns, we
	established that 1 unit == 1 gutter, and 2.58 gutters == 1 column
 */

/*.cols-1 {
	width:  6.15079364285714%;
}
.cols-2 {
	width:  14.6825396666667%;
}
.cols-3 {
	width:  23.2142856904762%;
}
.cols-4 {
	width:  31.7460317142857%;
}
.cols-5 {
	width:  40.2777777380952%;
}
.cols-6 {
	width:  48.8095237619048%;
}
.cols-7 {
	width:  57.3412697857143%;
}
.cols-8 {
	width:  65.8730158095238%;
}
.cols-9 {
	width:  74.4047618333333%;
}
.cols-10 {
	width:  82.9365078571429%;
}
.cols-11 {
	width:  91.4682538809524%;
}
.cols-12 {
	width:  100%;
}*/

/*.max-w-1-cols {
	max-width:  6.15079364285714%;
}
.max-w-2-cols {
	max-width:  14.6825396666667%;
}
.max-w-3-cols {
	max-width:  23.2142856904762%;
}*/


@layer utilities {

	.w-3-cols {
		width:  rem(292px);
	}

	.w-3-cols--plus {
		width:  rem(332px);
	}


.max-w-3-cols {
	max-width:  rem(292px);
}

.max-w-3-cols--plus {
	max-width:  rem(332px);
}

.max-w-4-cols {
	max-width:  rem(400px);
}

.max-w-4-cols--plus {
	max-width:  rem(430px);
}

.max-w-4pt5-cols {
	max-width:  rem(470px);
}

.w-5-cols {
	width:  rem(510px);
}

.w-5-cols--plus {
	width:  rem(540px);
}

.max-w-5-cols {
	max-width:  rem(510px);
}

.max-w-5-cols--plus {
	max-width:  rem(540px);
}

.w-6-cols {
	width:  rem(610px);
}

/*6 columns plus one gutter*/
.w-6-cols--plus {
	width:  rem(640px);
}

.max-w-6-cols {
	max-width:  rem(610px);
}

/*6 columns plus one gutter*/
.max-w-6-cols--plus {
	max-width:  rem(640px);
}

.max-w-7-cols {
	max-width:  rem(722px);
}

/*7 columns plus one gutter*/
.max-w-7-cols--plus {
	max-width:  rem(752px);
}

.max-w-8-cols {
	max-width:  rem(830px);
}
/*8 columns plus one gutter*/
.max-w-8-cols--plus {
	max-width:  rem(860px);
}

.max-w-9-cols {
	max-width:  rem(937px);
}
/*9 columns plus one gutter*/
.max-w-9-cols--plus {
	max-width:  rem(967px);
}

.max-w-10-cols {
	max-width:  rem(1044px);
}

.max-w-10-cols--plus {
	max-width:  rem(1074px);
}

.max-w-11-cols {
	max-width:  rem(1152px);
}

}

/*.max-w-9-cols {
	max-width:  74.4047618333333%;
}

.max-cols-11 {
	max-width:  91.4682538809524%;
}
.max-cols-12 {
	max-width:  100%;
}*/
