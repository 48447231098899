@font-face {
    font-family: '8th Light Sparks';
    src: url('/assets/fonts/sparks-v2-webfont.woff2') format('woff2'),
         url('/assets/fonts/sparks-v2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'GT Flexa Mono';
    font-weight:  normal;
    font-style: normal;
    font-display: swap;
    src:url("/assets/fonts/GT-Flexa-Mono-Regular.woff2") format("woff2"),url("/assets/fonts/GT-Flexa-Mono-Regular.woff") format("woff");
}

@font-face{
    font-family: 'GT Flexa';
    font-weight:  normal;
    font-style: normal;
    font-display: swap;
    src:url("/assets/fonts/GT-Flexa-Standard-Regular.woff2") format("woff2"),url("/assets/fonts/GT-Flexa-Standard-Regular.woff") format("woff");
}

@font-face{
    font-family: 'Avenir Next LT Pro';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src:url("/assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face{
    font-family: 'Avenir Next LT Pro';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src:url("/assets/fonts/AvenirNextLTPro-Demi.otf") format("opentype");
}

@font-face{
    font-family: 'Avenir Next LT Pro';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src:url("/assets/fonts/AvenirNextLTPro-Bold.otf") format("opentype");
}

@font-face{
    /*"Avenir Next LT W05 Demi"*/
    font-family: avenir;
    font-weight:  600;
    font-style: normal;
    font-display: swap;
    src:url("/assets/fonts/6149639/11ba579d-59f5-479e-b2dd-411ca230f60c.woff2") format("woff2"),url("/assets/fonts/6149639/f740c173-ce1a-4e47-a4e3-fab0a45da815.woff") format("woff");
}
@font-face{
    /*"Avenir Next LT W05 Demi Italic"*/
    font-family: avenir;
    font-weight:  600;
    font-style: italic;
    font-display: swap;
    src:url("/assets/fonts/6149643/296e79af-9ff5-4136-9548-a432704df310.woff2") format("woff2"),url("/assets/fonts/6149643/4d28346d-e4bc-4715-aefb-aeabe47c33f0.woff") format("woff");
}
@font-face{
    /*"Avenir Next LT W05 Bold"*/
    font-family: avenir;
    font-weight:  700;
    font-style: normal;
    font-display: swap;
    src:url("/assets/fonts/6149649/54a73ecc-af4b-4a5d-bc62-308e0fb53d83.woff2") format("woff2"),url("/assets/fonts/6149649/1cc134d7-e443-4d5b-887d-09e2af0aa119.woff") format("woff");
}
@font-face{
    /*"Avenir Next LT W05 Bold Italic"*/
    font-family: avenir;
    font-weight:  700;
    font-style: italic;
    font-display: swap;
    src:url("/assets/fonts/6634663/d0ff46ba-0e7c-45ac-af9c-6b7463a951f8.woff2") format("woff2"),url("/assets/fonts/6634663/d145a6dc-0b23-4226-87d5-ebbdc1810013.woff") format("woff");
}

/*@font-face{
    font-family:"Avenir Next LT W05 Regular";
    src:url("/assets/fonts/6149662/146f526d-792d-4257-ba12-0fb6fbb8e31e.woff2") format("woff2"),url("/assets/fonts/6149662/2a1d06fe-73d7-4df1-9d59-5227c3c01d03.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Italic";
    src:url("/assets/fonts/6149667/0f80ea09-8707-444b-a376-ed344302f2d9.woff2") format("woff2"),url("/assets/fonts/6149667/02a418e2-04df-4d9a-be95-5d6a2851fe40.woff") format("woff");
}*/
@font-face{
    /*"Avenir Next LT W05 Medium"*/
    font-family: avenir;
    font-weight:  400;
    font-style: normal;
    font-display: swap;
    src:url("/assets/fonts/6149672/9548fa3a-05c3-47ef-a6d5-f2f6fef54f84.woff2") format("woff2"),url("/assets/fonts/6149672/7667f1c1-c3ca-4520-aead-66f9d93d55a0.woff") format("woff");
}
@font-face{
    /*"Avenir Next LT W05 Medium It"*/
    font-family: avenir;
    font-weight:  400;
    font-style: italic;
    font-display: swap;
    src:url("/assets/fonts/6149677/1693592c-79e4-40a9-8a65-c06202b32b70.woff2") format("woff2"),url("/assets/fonts/6149677/d223f61f-151c-47b9-80e9-52d6105a3366.woff") format("woff");
}
/*@font-face{
    font-family:"Avenir Next LT W05 Ultra Light";
    src:url("/assets/fonts/6383935/5c55c8c0-87de-4bc7-8e0b-9fda6000edd9.woff2") format("woff2"),url("/assets/fonts/6383935/2d1881dc-9f0f-40ac-aca7-bf807adda3a1.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Ult Light I";
    src:url("/assets/fonts/6383939/e480d1a9-d3ac-4913-bc73-9ac7db7b22dc.woff2") format("woff2"),url("/assets/fonts/6383939/670ea3c7-d54a-4315-9e1b-a636050de209.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Thin";
    src:url("/assets/fonts/6383943/a53ad4b8-525b-4e51-9268-c73f5dd90a4b.woff2") format("woff2"),url("/assets/fonts/6383943/c515e242-b277-4aa6-be4b-a3833574b57a.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Thin Italic";
    src:url("/assets/fonts/6383947/b823504b-6245-4570-8e1c-60472e2e0360.woff2") format("woff2"),url("/assets/fonts/6383947/aa8415ae-f7de-48c4-818f-b8f745172dd0.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Heavy";
    src:url("/assets/fonts/6383962/41ae884e-ed1d-4cff-bd28-f16960d324db.woff2") format("woff2"),url("/assets/fonts/6383962/ddbbf731-24fd-4fab-aabc-1fd04130e79d.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Heavy Itali";
    src:url("/assets/fonts/6383966/f4e7fcfe-0c23-4195-a807-634c0945f835.woff2") format("woff2"),url("/assets/fonts/6383966/fb866dc4-8378-4efe-bcb6-6150123689b2.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Light";
    src:url("/assets/fonts/6383972/01fa22f2-c292-46a3-b2a3-e43acca0905f.woff2") format("woff2"),url("/assets/fonts/6383972/536593bd-b537-4d7e-a70f-5e1b3963ceec.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Light Itali";
    src:url("/assets/fonts/6383975/2683677c-0169-4b7a-9b43-b395191a3f42.woff2") format("woff2"),url("/assets/fonts/6383975/d0a3d31f-e2c5-4673-8d6a-2dee0095dad6.woff") format("woff");
}*/
